import Particles, { initParticlesEngine } from "@tsparticles/react";
import { useEffect, useMemo, useState } from "react";
// import { loadAll } from "@/tsparticles/all"; // if you are going to use `loadAll`, install the "@tsparticles/all" package too.
// import { loadFull } from "tsparticles"; // if you are going to use `loadFull`, install the "tsparticles" package too.
import { loadSlim } from "@tsparticles/slim"; // if you are going to use `loadSlim`, install the "@tsparticles/slim" package too.
// import { loadBasic } from "@tsparticles/basic"; // if you are going to use `loadBasic`, install the "@tsparticles/basic" package too.

const ParticlesComponent = (props) => {
    const [init, setInit] = useState(false);

    // this should be run only once per application lifetime
    useEffect(() => {
        initParticlesEngine(async (engine) => {
            // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
            // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
            // starting from v2 you can add only the features you need reducing the bundle size
            //await loadAll(engine);
            //await loadFull(engine);
            await loadSlim(engine);
            //await loadBasic(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    const options = useMemo(
        () => ({
            background: {
                color: {
                    value: "#000000",  // Changed to black
                },
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onClick: {
                        enable: true,
                        mode: "repulse",
                    },
                    onHover: {
                        enable: true,
                        mode: 'grab',
                    },
                },
                modes: {
                    push: {
                        distance: 200,
                        duration: 15,
                    },
                    grab: {
                        distance: 150,
                    },
                },
            },
            particles: {
                color: {
                    value: "#39868e",  // Keep particle color white
                },
                links: {
                    color: "#39868e",
                    distance: 150,
                    enable: true,
                    opacity: 0.3,
                    width: 1,
                },
                move: {
                    direction: "none",
                    enable: true,
                    outModes: {
                        default: "bounce",
                    },
                    random: true,
                    speed: 1,
                    straight: false,
                },
                number: {
                    density: {
                        enable: true,
                    },
                    value: 150,
                },
                opacity: {
                    value: 1.0,
                },
                shape: {
                    type: "circle",
                },
                size: {
                    value: { min: 1, max: 3 },
                },
            },
            detectRetina: true,
        }),
        [],
    );

    return <Particles id={props.id} init={particlesLoaded} options={options} />;
};

export default ParticlesComponent;





//
//
// import Particles, { initParticlesEngine } from "@tsparticles/react";
// import { useEffect, useMemo, useState } from "react";
// import { loadSlim } from "@tsparticles/slim";
//
// const ParticlesComponent = (props) => {
//     const [init, setInit] = useState(false);
//     const [isMoving, setIsMoving] = useState(false); // State to control particle movement
//
//     useEffect(() => {
//         initParticlesEngine(async (engine) => {
//             await loadSlim(engine);
//         }).then(() => {
//             setInit(true);
//         });
//
//         let scrollTimeout;
//
//         const handleScroll = () => {
//             setIsMoving(true); // Enable particle movement
//             clearTimeout(scrollTimeout);
//             scrollTimeout = setTimeout(() => {
//                 setIsMoving(false); // Disable particle movement after scrolling stops
//             }, 200); // Adjust delay as needed
//         };
//
//         window.addEventListener("scroll", handleScroll);
//
//         return () => {
//             window.removeEventListener("scroll", handleScroll);
//         };
//     }, []);
//
//     const particlesLoaded = (container) => {
//         console.log(container);
//     };
//
//     const options = useMemo(
//         () => ({
//             background: {
//                 color: {
//                     value: "#000000",
//                 },
//             },
//             fpsLimit: 120,
//             interactivity: {
//                 events: {
//                     onClick: {
//                         enable: true,
//                         mode: "repulse",
//                     },
//                     onHover: {
//                         enable: true,
//                         mode: "grab",
//                     },
//                 },
//                 modes: {
//                     push: {
//                         distance: 200,
//                         duration: 15,
//                     },
//                     grab: {
//                         distance: 150,
//                     },
//                 },
//             },
//             particles: {
//                 color: {
//                     value: "#39868e",
//                 },
//                 links: {
//                     color: "#39868e",
//                     distance: 150,
//                     enable: true,
//                     opacity: 0.3,
//                     width: 1,
//                 },
//                 move: {
//                     direction: "none",
//                     enable: isMoving, // Movement based on scroll state
//                     outModes: {
//                         default: "bounce",
//                     },
//                     random: true,
//                     speed: 1,
//                     straight: false,
//                 },
//                 number: {
//                     density: {
//                         enable: true,
//                     },
//                     value: 150,
//                 },
//                 opacity: {
//                     value: 1.0,
//                 },
//                 shape: {
//                     type: "circle",
//                 },
//                 size: {
//                     value: { min: 1, max: 3 },
//                 },
//             },
//             detectRetina: true,
//         }),
//         [isMoving], // Re-run when the movement state changes
//     );
//
//     return <Particles id={props.id} init={particlesLoaded} options={options} />;
// };
//
// export default ParticlesComponent;
